<template>
  <v-dialog
    v-if="editedDealer"
    :value="dialog"
    :persistent="isModelChanged()"
    :max-width="450"
    content-class="d-dialog"
    scrollable
    @keydown.esc="escape(closeDialog)"
    @click:outside="escape(closeDialog)"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ dealer ? $t('dealer.editDealer') : $t('dealer.createDealer') }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          tabindex="4"
          @click="escape(closeDialog)"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form" @submit.stop.prevent>
          <v-text-field
            v-model.trim="editedDealer.name"
            :rules="rules.name"
            :label="$t('dealer.edit.name')"
            tabindex="1"
            clearable
            autofocus
          ></v-text-field>
          <v-select
            v-if="currentUserIsChainAdmin"
            v-model="editedDealer.chainId"
            :rules="rules.chain"
            validate-on-blur
            :label="$t('dealer.edit.chain')"
            :items="chains"
            item-value="id"
            item-text="name"
            tabindex="1"
            autofocus
          ></v-select>
          <v-text-field
            v-model.trim="editedDealer.phone"
            :rules="rules.phone"
            :label="$t('dealer.edit.phone')"
            type="tel"
            tabindex="1"
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="editedDealer.url"
            :rules="rules.url"
            validate-on-blur
            :label="$t('dealer.edit.url')"
            type="url"
            tabindex="1"
            clearable
          ></v-text-field>
          <v-text-field
            v-model.trim="editedDealer.emailAddress"
            :rules="rules.emailAddress"
            validate-on-blur
            :label="$t('dealer.edit.email')"
            type="email"
            tabindex="1"
            clearable
          ></v-text-field>
          <v-checkbox
            v-model="editedDealer.isActive"
            :label="$t('dealer.edit.active')"
            tabindex="1"
          ></v-checkbox>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-text="$t('cancel')"
          color="secondary"
          text
          tabindex="3"
          @click="closeDialog"
        ></v-btn>
        <v-btn
          v-text="$t('save')"
          :disabled="!isModelChanged()"
          color="primary"
          text
          tabindex="2"
          @click="submit"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import { dealerSchema, userRole } from '@/constants'

import objectUtil from '@/utils/object.util'

import dialogMixin from '@/mixins/dialog.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import errorMixin from '@/mixins/error.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    dealer: Object
  },


  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    errorMixin
  ],


  data () {
    return {
      editedDealer: null,
      defaultDealer: {
        name: '',
        emailAddress: '',
        phone: '',
        url: '',
        isActive: true
      },
      existingNames: []
    }
  },

  computed: {
    ...mapGetters('chain', ['chains', 'currentChainId']),
    ...mapGetters('user', ['currentUser']),

    currentUserIsChainAdmin () {
      return this.currentUser.roleId === userRole.chainAdmin
    },

    rulesToApply () {
      const rule = this.rule

      return {
        name: [
          rule.required(),
          rule.maxLength(dealerSchema.nameMaxLength),
          rule.unique(this.existingNames, this.$t('dealer.edit.nameExists'))
        ],
        phone: [rule.maxLength(dealerSchema.phoneMaxLength)],
        url: [rule.url(), rule.maxLength(dealerSchema.urlMaxLength)],
        emailAddress: [rule.required(), rule.email(), rule.maxLength(dealerSchema.emailMaxLength)],
        chain: [rule.required()]
      }
    }
  },


  methods: {
    ...mapActions('dealer', ['createDealer', 'createDealerWithChainId', 'updateDealer', 'updateDealerWithChainId']),

    onDialogOpen () {
      if (this.currentUserIsChainAdmin) {
        this.defaultDealer.chainId = this.currentChainId
      } else {
        this.defaultDealer.chainId = this.currentUser.chainId
      }
      this.editedDealer = objectUtil.getObjectCopy(this.dealer || this.defaultDealer)

      this.initWatchedModel(this.editedDealer)
      this.resetValidation()

      this.existingNames = []
    },

    async submit () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        const dealer = objectUtil.getObjectCopy(this.editedDealer)

        if (dealer.id) {
          if (this.currentUserIsChainAdmin) {
            await this.updateDealer(dealer)
          } else {
            await this.updateDealerWithChainId({ dealer, chainId: dealer.chainId })
          }
          this.showSnackbarSuccess(this.$t('dealer.dealerUpdated'))
          this.$emit('dealerEdited')
        } else {
          if (this.currentUserIsChainAdmin) {
            await this.createDealer(dealer)
          } else {
            dealer.chainId = this.currentUser.chainId
            await this.createDealerWithChainId({ dealer, chainId: dealer.chainId })
          }
          this.showSnackbarSuccess(this.$t('dealer.dealerCreated'))
        }

        this.closeDialog()
      } catch (error) {
        if (this.is409ConflictError(error)) {
          this.hideSnackbar()
          this.existingNames.push(this.editedDealer.name)
          this.validate(this.rulesToApply)
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  }
}
</script>
