<template>
  <div class="d-page-wrapper">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
      <v-btn
        v-text="'+ ' + $t('dealer.createDealer')"
        class="d-btn-1 ml-auto"
        depressed
        @click="openEditDialog(null)"
      ></v-btn>
    </div>
    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :page="pageNumber"
      :items-per-page="pageSize"
      :server-items-length="dealersTotalCount"
      disable-sort
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
      @click:row="openEditDialog"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-icon class="d-data-table-cell-icon">mdi-account-cash</v-icon>
        {{ item.name }}
      </template>
      <template v-slot:[`item.chain`]="{ item }">
        <v-icon class="d-data-table-cell-icon">mdi-link-variant</v-icon>
        {{ item.chain }}
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <a v-if="item.url" :href="item.url" target="_blank">{{ item.url }}</a>
      </template>
      <template v-slot:[`item.emailAddress`]="{ item }">
        <a v-if="item.emailAddress" :href="'mailto:' + item.emailAddress">{{ item.emailAddress }}</a>
      </template>
      <template v-slot:[`item.chain`]="{ item }">
        {{ item.chain }}
      </template>
      <template v-slot:[`item.isActive`]="{ item }">
        <d-active-status-icon :value="item.isActive"></d-active-status-icon>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="text-no-wrap d-visible-on-row-hover">
          <d-btn-icon type="edit" @click="openEditDialog(item)"></d-btn-icon>
          <d-btn-icon v-if="item.id === currentUser.dealerId" type="lock"></d-btn-icon>
          <d-btn-icon v-else type="delete" @click="deleteDealer_(item)"></d-btn-icon>
        </div>
      </template>
    </v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
      @input="onPageInput"
    ></v-pagination>

    <dealer-edit-dialog
      :dialog.sync="editDialog"
      :dealer="editDialog_dealer"
      @dealerEdited="onDealerEdited"
    ></dealer-edit-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { dealersPageSize, userRole } from '@/constants'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import errorMixin from '@/mixins/error.mixin'

import DSearchInput from '@/components/DSearchInput'
import DBtnIcon from '@/components/DBtnIcon'
import DActiveStatusIcon from '@/components/DActiveStatusIcon'
import DealerEditDialog from './DealerEditDialog'


export default {
  components: {
    DSearchInput,
    DBtnIcon,
    DActiveStatusIcon,
    DealerEditDialog
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    errorMixin
  ],


  data () {
    return {
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: dealersPageSize,
      editDialog: false,
      editDialog_dealer: null
    }
  },


  computed: {
    ...mapGetters('user', ['currentUser', 'currentUserIsChainAdmin']),
    ...mapGetters('chain', ['currentChainId']),
    ...mapGetters('dealer', ['currentDealerId', 'dealersTableItems', 'dealersTotalCount']),

    tableHeaders () {
      return [
        { text: this.$t('dealer.table.name'), value: 'name', cellClass: 'font-weight-medium' },
        { text: this.$t('dealer.table.chain'), value: 'chainName' },
        { text: this.$t('dealer.table.phone'), value: 'phone' },
        { text: this.$t('dealer.table.url'), value: 'url' },
        { text: this.$t('dealer.table.email'), value: 'emailAddress' },
        { text: this.$t('dealer.table.active'), value: 'isActive' },
        { text: '', value: 'actions', align: 'end', width: 1, sortable: false, filterable: false }
      ]
    },

    tableItems () {
      return this.dealersTableItems
    }
  },

  watch: {
    currentChainId (currentChainId) {
      this.reloadTableItems(1)
    }
  },


  methods: {
    ...mapActions('dealer', ['loadDealersTableItems', 'loadDealersTableItemsByChainId', 'restoreInitialDealersTableItems', 'setCurrentDealerId', 'deleteDealer']),
    ...mapActions('chain', ['loadChains']),

    openEditDialog (dealer) {
      this.editDialog_dealer = dealer
      this.editDialog = true
    },

    onDealerEdited () {
      this.reloadTableItems(1)
    },

    onSearchSubmit () {
      this.reloadTableItems(1)
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page, isInitial = false) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }

        const chainId = this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
        await this.loadDealersTableItemsByChainId({
          chainId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search,
          isInitial
        })
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    },

    async deleteDealer_ (dealer) {
      try {
        const confirm = await this.$confirm(this.$t('dealer.deleteDealerConfirm', dealer), { trueText: this.$t('delete') })

        if (confirm === true) {
          this.showSnackbarProcessing()
          await this.deleteDealer(dealer.id)
          this.showSnackbarSuccess(this.$t('dealer.dealerDeleted'))

          if (dealer.id === this.currentDealerId) {
            this.setCurrentDealerId(this.currentUser.dealerId)
          }

          if (!this.tableItems.length) {
            const page = (this.pageNumber - 1) || 1
            this.reloadTableItems(page)
          }
        }
      } catch (error) {
        if (this.is405NotAllowedError(error)) {
          this.hideSnackbar()
          await this.$alert(this.$t('dealer.cannotDeleteWithRelations', dealer), { title: this.$t('failed') })
        } else {
          this.showSnackbarError()
        }
        this.$log.error(error)
      }
    }
  },


  mounted () {
    this.restoreInitialDealersTableItems()
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.dealers'))
      this.startPageLoading()

      if (this.currentUser.roleId === userRole.chainAdmin) {
        await this.loadChains()
        // await this.loadDealersTableItems({
        //   pageNumber: this.pageNumber,
        //   pageSize: this.pageSize,
        //   isInitial: true
        // })
        await this.loadDealersTableItemsByChainId({
          chainId: this.currentChainId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          isInitial: true
        })
      } else {
        await this.loadDealersTableItemsByChainId({
          chainId: this.currentUser.chainId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          isInitial: true
        })
      }
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
